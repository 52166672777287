// import React from "react";
// import ReactFlow, { useNodesState, useEdgesState, Controls } from "reactflow";
// import { useTranslation } from "react-i18next";

// const initialEdges = [
//   {
//     id: "el-1",
//     source: "1",
//     type: "smoothstep",
//     target: "3",
//     animated: true,
//   },
//   {
//     id: "el-3",
//     source: "3",
//     type: "smoothstep",
//     target: "4",
//     animated: true,
//   },
//   {
//     id: "el-4",
//     source: "4",
//     type: "smoothstep",
//     target: "6",
//     animated: true,
//   },
//   {
//     id: "el-6",
//     source: "6",
//     type: "smoothstep",
//     target: "7",
//     animated: true,
//   },
//   {
//     id: "el-7",
//     source: "6",
//     type: "smoothstep",
//     target: "8",
//     animated: true,
//   },
// ];

// const FlowChart = () => {
//   const { t } = useTranslation();

//   const totalWidth = 1000;
//   const nodeCount = 6;
//   const horizontalSpacing = totalWidth / (nodeCount - 1);
//   const verticalSpacing = 100; // Adjust this value as needed

//   const initialNodes = [
//     {
//       id: "1",
//       sourcePosition: "right",
//       data: { label: t("solutions.process.flow.step.one") },
//       type: "input",
//       position: { x: 0, y: 80 },
//     },
//     {
//       id: "3",
//       sourcePosition: "right",
//       targetPosition: "left",
//       data: { label: t("solutions.process.flow.step.three") },
//       position: { x: horizontalSpacing, y: 80 },
//     },
//     {
//       id: "4",
//       sourcePosition: "right",
//       targetPosition: "left",
//       data: { label: t("solutions.process.flow.step.four") },
//       position: { x: horizontalSpacing * 2, y: 80 },
//     },
//     {
//       id: "6",
//       sourcePosition: "right",
//       targetPosition: "left",
//       data: { label: t("solutions.process.flow.step.six") },
//       position: { x: horizontalSpacing * 3, y: 80 },
//     },
//     {
//       id: "7",
//       targetPosition: "left",
//       type: "output",
//       data: { label: t("solutions.process.flow.step.seven") },
//       position: { x: horizontalSpacing * 4, y: 80 - verticalSpacing },
//     },
//     {
//       id: "8",
//       targetPosition: "left",
//       type: "output",
//       data: { label: t("solutions.process.flow.step.eight") },
//       position: { x: horizontalSpacing * 4, y: 80 + verticalSpacing },
//     },
//   ];

//   const [nodes] = useNodesState(initialNodes);
//   const [edges] = useEdgesState(initialEdges);

//   return (
//     <>
//       <ReactFlow
// nodes={nodes}
// edges={edges}
// zoomOnDoubleClick={false}
// zoomOnPinch={false}
// fitView
// proOptions={{ hideAttribution: true }}
// className="bg-blue-50 rounded-lg font-bold"
// nodesConnectable={false}
// nodesDraggable={false}
// elementsSelectable={false}
//       >
//         <Controls showInteractive={false} />
//       </ReactFlow>
//     </>
//   );
// };

// export default FlowChart;

import React from "react";
import ReactFlow, { Background } from "reactflow";
import { useTranslation } from "react-i18next";
import "reactflow/dist/style.css";

const edges = [
  {
    id: "e1",
    source: "black_mass_1",
    target: "greenlib",
    animated: true,
    style: { stroke: "green" },
  },
  {
    id: "e2",
    source: "greenlib",
    target: "refined_outputs",
    animated: true,
    style: { stroke: "green" },
  },
  {
    id: "e3",
    source: "refined_outputs",
    target: "recyclers_1",
    animated: true,
    style: { stroke: "green" },
  },
  {
    id: "e4",
    source: "black_mass_2",
    target: "recyclers_2",
    animated: true,
    style: { stroke: "red", strokeDasharray: "5,5" },
  },
  {
    id: "e5",
    source: "black_mass_1",
    animated: true,
    style: { stroke: "black", strokeDasharray: "5,5" },
  },
];

const FlowChart = () => {
  const { t } = useTranslation();

  const nodes = [
    {
      id: "value_added_stream",
      data: { label: t("solutions.process.flow.step.one") },
      position: { x: 50, y: 20 },
      style: { background: "#d4f8c4", fontWeight: "bold" },
    },
    {
      id: "black_mass_1",
      data: { label: t("solutions.process.flow.step.two") },
      position: { x: 50, y: 50 },
      style: {
        background: "white",
        padding: 10,
        borderRadius: 10,
        border: "1px solid black",
      },
    },
    {
      id: "greenlib",
      data: { label: t("solutions.process.flow.step.three") },
      position: { x: 300, y: 50 },
      style: {
        background: "#d4f8c4",
        padding: 10,
        borderRadius: 10,
        border: "1px solid black",
      },
    },
    {
      id: "refined_outputs",
      data: { label: t("solutions.process.flow.step.four") },
      position: { x: 550, y: 50 },
      style: {
        background: "#d4f8c4",
        padding: 10,
        borderRadius: 10,
        border: "1px solid black",
      },
    },
    {
      id: "recyclers_1",
      data: { label: t("solutions.process.flow.step.five") },
      position: { x: 800, y: 50 },
      style: {
        background: "white",
        padding: 10,
        borderRadius: 10,
        border: "1px solid black",
      },
    },
    {
      id: "low_value_stream",
      data: { label: t("solutions.process.flow.step.six") },
      position: { x: 50, y: 170 },
      style: { background: "#f8c4c4", fontWeight: "bold" },
    },
    {
      id: "black_mass_2",
      data: { label: t("solutions.process.flow.step.seven") },
      position: { x: 50, y: 200 },
      style: {
        background: "white",
        padding: 10,
        borderRadius: 10,
        border: "1px solid black",
      },
    },
    {
      id: "recyclers_2",
      data: { label: t("solutions.process.flow.step.eight") },
      position: { x: 300, y: 200 },
      style: {
        background: "white",
        padding: 10,
        borderRadius: 10,
        border: "1px solid black",
      },
    },
  ];

  return (
    <div className="w-full h-[500px] border p-4">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        fitView
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
        proOptions={{ hideAttribution: true }}
        className="bg-blue-50 rounded-lg font-bold"
        nodesConnectable={false}
        nodesDraggable={false}
        elementsSelectable={false}
      >
        <Background />
      </ReactFlow>
    </div>
  );
};

export default FlowChart;
