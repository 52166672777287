const french = {
  navigation: {
    solutions: "Solutions",
    about: "À propos",
    contact: "Contact",
    team: "Équipe",
  },
  footer: {
    tag: "",
    solutions: "Solutions",
    company: "Entreprise",
    about: "À propos",
    contact: "Contact",
    legal: "Mentions légales",
    privacy: "Confidentialité",
    team: "Équipe",
  },
  home: {
    hero: {
      title: {
        one: "Processus de prétraitement en une seule étape qui extrait et affine",
        two: "les éléments critiques des batteries",
      },
      subtitle: "Technologie de raffinage avancée.",
      cta: "Commencez ici",
    },
    main: {
      title: "Problème",
      subtitle:
        "La masse noire contient du lithium, du graphite, du fluor et de l'aluminium, qui perturbent le raffinage et augmentent les coûts.",
      text: "Les méthodes de recyclage traditionnelles rencontrent de multiples défis : le fluor forme du HF corrosif, augmentant les coûts de maintenance ; l'excès d'aluminium accroît l'utilisation d'acide et les boues résiduelles ; les métaux piégés réduisent la récupération ; le lithium perturbe le traitement ; et les sous-produits goudronneux obstruent les équipements. Les améliorations sont coûteuses et la variabilité de la masse noire entraîne des incertitudes.",
    },
    info: {
      one: {
        title: "Récupération de matériaux",
        subtitle: "Extraction améliorée",
        text: "Nous aidons les fonderies et les recycleurs à optimiser leur récupération.",
      },
      two: {
        title: "Adaptabilité du processus",
        subtitle: "Traitement flexible",
        text: "Adaptation à un paysage matériel diversifié.",
      },
      three: {
        title: "Amélioration environnementale",
        subtitle: "Pratiques écologiques",
        text: "Minimiser l'impact, maximiser l'efficacité",
      },
    },
    solution: {
      title: "Solution",
      subtitle: "Technologie de l'électroextraction et de l'eau",
      text: {
        one: "GreenLIB complète les fonderies et recycleurs existants en offrant un prétraitement à basse température à base d'eau qui élimine le HF et les PFAS, réduisant ainsi la corrosion et les arrêts de production. Nous éliminons l'aluminium dès le début—réduisant l'utilisation d'acide et les boues—tout en extrayant le lithium et le graphite pour améliorer la récupération du nickel et du cobalt. En réduisant les résidus de liant, nous prévenons les pannes du système et garantissons des rendements stables.",
        two: "Le résultat ? Des coûts réduits, moins d'arrêts de production et une production métallique constante, augmentant ainsi la valeur des actifs de nos partenaires et favorisant un avenir plus propre et plus efficace dans le recyclage des batteries.",
      },
      action_one: "Recyclez avec nous",
      action_two: "En savoir plus",
    },
    cta: {
      title: "Découvrez notre approche unique et évolutive.",
      action: "En savoir plus",
    },
  },
  solutions: {
    hero: {
      title: "Alimenter l'avenir du surcyclage des matériaux.",
      subtitle:
        "Sur le marché complexe du recyclage des batteries, GreenLIB se distingue en transformant les défis de l'industrie en opportunités de croissance et d'innovation. En abordant des problèmes critiques tels que les marges bénéficiaires, la conformité environnementale et la qualité des matériaux, notre technologie avancée de surcyclage rationalise les processus, réduit les coûts logistiques et fournit des matériaux de haute pureté, établissant de nouvelles normes de durabilité et d'efficacité face à la demande mondiale croissante. Avec GreenLIB, l'avenir du recyclage des batteries est non seulement plus vert et plus propre, mais également économiquement viable et aligné sur les besoins pressants de notre époque.",
      action: "Recyclez avec nous",
    },
    minerals: {
      title:
        "Les métaux et minéraux essentiels que nous obtenons grâce à notre processus.",
      action: "Sélectionnez un minéral pour en savoir plus !",
      atomic: {
        title: "Numéro atomique {{atomicNumber}}",
        lithium: {
          name: "Lithium",
          num: "7",
          text: "Le lithium est le métal le plus léger et le troisième élément le plus léger (après l'hydrogène et l'hélium) du tableau périodique. Il a une faible densité et est très réactif, ce qui le rend utile pour une variété d'applications, y compris dans les batteries, les céramiques et même les médicaments pour certaines conditions de santé.",
        },
        silver: {
          name: "Argent",
          num: "47",
          text: "L'argent est parfois utilisé dans les électrodes des batteries, mais c'est un phénomène relativement rare. Cependant, l'argent est un métal hautement recyclable qui peut être réutilisé dans la production de nouvelles batteries.",
        },
        manganese: {
          name: "Manganèse",
          num: "25",
          text: "Le manganèse est un composant courant des batteries au lithium-ion. Il est souvent utilisé dans la cathode pour améliorer la densité énergétique de la batterie. Le manganèse peut être recyclé à partir de vieilles batteries et utilisé pour produire de nouvelles.",
        },
        iron: {
          name: "Fer",
          num: "26",
          text: "Le fer n'est pas typiquement utilisé dans les batteries, mais il est un composant courant de l'acier, utilisé pour fabriquer des boîtiers de batteries et d'autres composants. Le fer recyclé peut être utilisé pour fabriquer du nouvel acier à ces fins.",
        },
        cobalt: {
          name: "Cobalt",
          num: "27",
          text: "Le cobalt est utilisé dans les batteries au lithium-ion pour augmenter leur densité énergétique. Le cobalt est un minéral précieux, mais son extraction peut avoir des impacts environnementaux négatifs. Le recyclage des batteries peut aider à réduire le besoin de nouvelles extractions et à conserver des ressources précieuses.",
        },
        nickel: {
          name: "Nickel",
          num: "28",
          text: "Le nickel est un composant clé de la cathode de nombreux types de batteries, y compris les batteries au nickel-cadmium, nickel-hydrure métallique et lithium-ion. Le nickel peut être recyclé à partir de vieilles batteries et utilisé pour produire de nouvelles.",
        },
        copper: {
          name: "Cuivre",
          num: "29",
          text: "Le cuivre est utilisé dans de nombreux composants de batteries, y compris les fils, les connecteurs et les bornes. Le cuivre est également un métal hautement recyclable qui peut être réutilisé dans la production de nouvelles batteries.",
        },
        aluminium: {
          name: "Aluminium",
          num: "13",
          text: "L'aluminium n'est pas directement utilisé dans les batteries, mais il est souvent utilisé pour fabriquer des boîtiers de batteries et d'autres composants. Le recyclage de l'aluminium peut aider à conserver l'énergie et à réduire les émissions de gaz à effet de serre.",
        },
        carbon: {
          name: "Carbone",
          num: "6",
          text: "Le carbone est utilisé dans l'anode de nombreux types de batteries, y compris les batteries au lithium-ion. Le carbone peut être recyclé à partir de vieilles batteries et utilisé pour produire de nouvelles.",
        },
        silicon: {
          name: "Silicium",
          num: "14",
          text: "Le silicium est un métalloïde polyvalent abondant dans la croûte terrestre. Avec sa conductivité électrique, sa résistance à la chaleur et son affinité pour l'oxygène, il est essentiel dans l'électronique, les panneaux solaires, la fibre optique, le béton et la production de verre.",
        },
        electrolyte: {
          name: "Électrolyte",
          num: "LiPF6",
          text: "Le hexafluorophosphate de lithium (LiPF6) est un électrolyte essentiel dans les batteries au lithium-ion, facilitant le transfert d'ions entre les électrodes pour permettre le stockage et la libération d'énergie. Sa haute conductivité ionique et sa stabilité en font un ingrédient clé pour des batteries haute performance dans l'électronique et les véhicules électriques.",
        },
      },
    },
    process: {
      title: "Notre processus",
      flow: {
        step: {
          one: "Flux à valeur ajoutée",
          two: "Masse noire (batteries en fin de vie / déchets)",
          three: "GreenLIB",
          four: "Produits raffinés (Ni/Co pur, lithium, graphite, aluminium)",
          five: "Recycleurs (fonderies / hydrométallurgie)",
          six: "Flux à faible valeur",
          seven: "Masse noire (batteries en fin de vie / déchets)",
          eight: "Recycleurs (fonderies / hydrométallurgie)",
        },
      },
    },
    info: {
      one: {
        title: "Maximiser la récupération des ressources",
        text: "Nous améliorons la récupération des matériaux critiques—lithium, graphite, nickel, cobalt et électrolytes—en optimisant le traitement de la masse noire. Notre approche réduit les déchets, améliore l'efficacité et s’aligne sur l’évolution des réglementations pour favoriser une économie circulaire.",
      },
      two: {
        title: "Traitement adaptable et évolutif",
        text: "Notre technologie s'intègre parfaitement aux fonderies, aux recycleurs et aux producteurs de masse noire, en prenant en charge diverses chimies de batteries comme NMC, LFP et LMFP. Cette flexibilité garantit un prétraitement efficace et économique qui améliore la récupération en aval.",
      },
      three: {
        title: "Des opérations durables et rentables",
        text: "Nous réduisons la dépendance aux permis de gestion des déchets dangereux en éliminant la formation de HF et de PFAS tout en gérant efficacement le fluor. Notre processus réduit l'utilisation d'acide, diminue les émissions et simplifie l’élimination des déchets—rendant le recyclage des batteries plus écologique et plus rentable.",
      },
      four: {
        title: "Augmenter la valeur des actifs",
        text: "En complétant les infrastructures existantes, nous aidons les fonderies et les recycleurs à augmenter les rendements métalliques, réduire les coûts d'exploitation et améliorer la rentabilité. Notre solution optimise l’efficacité des processus, rendant le recyclage des batteries plus viable à grande échelle.",
      },
    },
    cta: {
      title:
        "Intéressé pour en savoir plus ? Discutons de comment nous pouvons vous aider.",
      action: "Contactez-nous",
    },
  },
  about: {
    hero: {
      title: "Notre parcours vers un futur durable",
      subtitle:
        "Lancé en 2022, nous sommes prêts à transformer le secteur du surcyclage des matériaux. Avec un processus innovant pour récupérer les composants précieux des batteries, nous transformons les défis de l'industrie en solutions durables. Notre technologie non seulement augmente les taux de recyclage au-delà des 5% actuels mais réduit également les émissions de CO2 et les coûts. Conçu pour la décentralisation, nos installations de prétraitement sur site s'intègrent parfaitement dans les chaînes d'approvisionnement existantes, améliorant la durabilité à long terme. Alors que la demande de batteries augmente, nous sommes prêts à mener avec un surcyclage éco-efficace de la masse noire pour un avenir énergétique plus durable.",
    },
    stat: {
      title: "Notre mission",
      subtitle:
        "Nous sommes animés par la mission de transformer l'industrie du recyclage – en mettant l'accent sur le surcyclage de la masse noire. Notre objectif est de fournir une valeur inégalée grâce à un processus aussi écologique que sécurisé et efficace.",
      text: "Notre engagement est d'inspirer chaque membre de notre équipe et nos partenaires à travers notre dévouement inébranlable à la préservation de l'environnement et à la poursuite incessante de l'innovation. Nous promettons non seulement la faisabilité de notre méthodologie, mais aussi l'extraction consciencieuse de matériaux vitaux, tout pour renforcer le passage mondial vers l'énergie propre.",
      one: {
        label:
          "Nous sommes prêts à révolutionner la récupération de matériaux d'ici 2030, visant à traiter 16 500 tonnes métriques de masse noire annuellement provenant de divers secteurs. Nos installations avancées sont spécialisées dans l'extraction de matériaux de haute qualité, y compris du lithium purifié, du graphite et des électrolytes, ainsi que des alliages propres de nickel/cobalt.",
        value: "Visant 16 500 tonnes métriques annuelles d'ici 2030",
      },
      two: {
        label:
          "Notre technologie ne se contente pas de récupérer des matériaux vitaux, mais illustre également notre engagement envers la gestion des déchets et le contrôle de la pollution. Notre méthodologie est centrée sur une gestion rigoureuse du fluor et une réduction des PFAS, ce qui réduit considérablement l'impact environnemental du recyclage des batteries. Nous visons un écosystème où les déchets chimiques et polluants sont minimisés, en accord avec les normes environnementales strictes.",
        value: "Innover pour l'excellence environnementale",
      },
      three: {
        label:
          "Nos processus flexibles gèrent efficacement divers types de masse noire allant du NMC au LFP/LMFP ou mix, reflétant notre engagement envers la durabilité et la qualité face à la diversité des chimies matérielles. Nous facilitons l'intégration de pratiques plus propres et plus efficaces pour les fonderies/pyro et les processus hydrométallurgiques, garantissant que chaque étape que nous prenons est un pas vers un avenir plus vert.",
        value: "Adaptable à diverses chimies de batteries",
      },
    },
    values: {
      title: "Nos valeurs",
      subtitle:
        "Chez GreenLIB, nous sommes engagés à créer un avenir durable pour notre planète en fournissant un moyen sûr et efficace de surcycler la masse noire.",
      innovation: {
        name: "Innovation",
        description:
          "Chez GreenLIB, nous croyons en la puissance de l'innovation pour entraîner le changement. Nous explorons constamment de nouvelles méthodes avancées pour surcycler la masse noire de manière plus écologique et durable. Notre équipe d'experts travaille sans relâche à développer des technologies de pointe qui nous aident à extraire la valeur maximale de ces batteries tout en minimisant les déchets et les impacts environnementaux.",
      },
      integrity: {
        name: "Intégrité",
        description:
          "L'intégrité est au cœur de tout ce que nous faisons. Nous nous tenons aux normes éthiques les plus élevées, et notre engagement envers la transparence et l'honnêteté est inébranlable. Nous nous efforçons de bâtir la confiance et la crédibilité avec nos clients, fournisseurs et partenaires en faisant toujours ce qu'il faut, même lorsque c'est difficile.",
      },
      environmentalResponsibility: {
        name: "Responsabilité environnementale",
        description:
          "Nous comprenons l'urgence des défis environnementaux auxquels notre planète est confrontée aujourd'hui. En tant que citoyen corporatif responsable, nous nous engageons à réduire notre impact environnemental et à promouvoir la durabilité. Notre processus de recyclage est conçu pour minimiser les déchets et les émissions de carbone, conserver les ressources et contribuer à l'économie circulaire.",
      },
      customerFocus: {
        name: "Orientation client",
        description:
          "Nos clients sont au centre de tout ce que nous faisons. Nous nous engageons à leur fournir le plus haut niveau de service, de produits et de solutions qui répondent à leurs besoins et attentes uniques. Nous écoutons leurs commentaires et nous efforçons constamment d'améliorer nos processus et opérations pour mieux les servir.",
      },
      collaboration: {
        name: "Collaboration",
        description:
          "Nous pensons que la collaboration est essentielle pour atteindre nos objectifs. Nous travaillons étroitement avec nos partenaires, fournisseurs et clients pour créer un avenir plus durable pour notre planète. En travaillant ensemble, nous pouvons trouver des solutions innovantes aux défis environnementaux auxquels nous sommes confrontés et avoir un impact positif sur le monde.",
      },
    },
    cta: {
      title: "Découvrez notre approche unique et évolutive.",
      action: "En savoir plus",
    },
  },
  contact: {
    title: "Contact",
    subtitle: "Construisons ensemble l'avenir de l'énergie durable.",
    form: {
      firstName: "Prénom",
      lastName: "Nom",
      company: "Société",
      email: "Email",
      message: "Message",
      errorMessage: "Obligatoire",
      action: "Parlons-en !",
      success:
        "Merci de nous avoir contacté ! Quelqu'un vous contactera bientôt.",
    },
    info: {
      text: "D'ici 2030, nous visons à traiter 16 500 tonnes métriques de masse noire par an, nous établissant comme des leaders dans la récupération durable des matériaux. Notre technologie propriétaire est à la pointe du surcyclage de la masse noire provenant de diverses industries en ressources précieuses comme le carbonate de lithium, le graphite, l'électrolyte et des alliages métalliques de qualité supérieure. Ce processus garantit non seulement des entrées de haute pureté pour la production de batteries mais privilégie également la gestion environnementale en minimisant les déchets et les substances nocives. Nous collaborons directement avec des fonderies et des processeurs hydro pour affiner et revendre nos solutions avancées sur site. Cette approche réduit les coûts logistiques et matériels, surmontant les barrières traditionnelles au recyclage. Alors que la demande pour un recyclage de batteries efficace et écologique s'intensifie, nous sommes prêts à répondre à ce besoin avec nos technologies à faibles émissions de CO2 et rentables. Contactez-nous pour en savoir plus sur la façon dont nous alimentons la transition énergétique propre.",
    },
  },
  team: {
    title: "Rencontrez notre direction",
    subtitle:
      "Unis par notre dévouement à l'énergie durable et à la protection de l'environnement, notre équipe comprend des innovateurs, des visionnaires et des spécialistes de haut niveau dans le domaine.",
    advisory: "Conseil consultatif",
    titles: {
      advisor: "Conseiller",
      ceo: "PDG",
      coo: "COO",
      cto: "CTO",
      headRD: "Chef de la R&D",
    },
  },
  relationships: {
    title: "Nos relations",
  },
  batteryChart: {
    xLabel: "Composants du coût de la batterie au lithium",
    yLabel: "$ / kWh",
    legend: {
      cathode: "Cathode",
      anode: "Anode",
      electrolyte: "Électrolyte",
      separator: "Séparateur",
      otherMaterials: "Autres matériaux",
      labor: "Main-d'œuvre",
      overhead: "Frais généraux",
      profitMargin: "Marge bénéficiaire",
    },
  },
};

export default french;
