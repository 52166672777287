const english = {
  navigation: {
    solutions: "Solutions",
    about: "About",
    contact: "Contact",
    team: "Team",
  },
  footer: {
    tag: "",
    solutions: "Solutions",
    company: "Company",
    about: "About",
    contact: "Contact",
    legal: "Legal",
    privacy: "Privacy",
    team: "Team",
  },
  home: {
    hero: {
      title: {
        one: "One-step pre-treatment process that extracts and refines",
        two: " critical battery elements",
      },
      subtitle: "Advanced refinement technology.",
      cta: "Start here",
    },
    main: {
      title: "Problem",
      subtitle:
        "Black mass contains lithium, graphite, fluorine, and aluminum, which disrupt refining and increase costs.",
      text: "Traditional recycling methods face multiple challenges: fluorine forms corrosive HF, driving up maintenance; excess aluminum raises acid use and waste sludge; trapped metals reduce recovery; lithium disrupts processing; and tar-like byproducts clog equipment. Upgrades are costly, and variable black mass fuels yield uncertainties.",
    },
    info: {
      one: {
        title: "Material recovery",
        subtitle: "Enhanced extraction",
        text: "We help smelters and recyclers streamline their recovery.",
      },
      two: {
        title: "Process adaptability",
        subtitle: "Flexible processing",
        text: "Adapting to a diverse material landscape.",
      },
      three: {
        title: "Environmental enhancement",
        subtitle: "Eco-friendly practices",
        text: "Minimizing impact, maximizing efficiency",
      },
    },
    solution: {
      title: "Solution",
      subtitle: "Electroextraction and water technology technology",
      text: {
        one: "GreenLIB complements existing smelters and recyclers by providing a low-temperature, water-based pre-treatment that eliminates HF and PFAS, reducing corrosion and downtime. We remove aluminum early—cutting acid use and sludge—while extracting lithium and graphite to boost nickel and cobalt recovery. By slashing binder residues, we prevent system failures and ensure stable yields.",
        two: "The result? Lower costs, fewer shutdowns, and a consistent metal output, raising asset value for our partners and driving a cleaner, more efficient future in battery recycling.",
      },
      action_one: "Recycle with us",
      action_two: "Learn more",
    },
    cta: {
      title: "Learn about our unique and scalable approach.",
      action: "Learn more",
    },
  },
  solutions: {
    hero: {
      title: "Rethinking Battery Recycling: Smarter, Cleaner, Better",
      subtitle:
        "Traditional battery recycling is costly, inefficient, and full of roadblocks—corrosive byproducts, excessive waste, and unpredictable yields. GreenLIB changes the game. Our low-temperature thermal and water-based process eliminates HF and PFAS, removes aluminum early to cut waste, and extracts lithium and graphite cleanly to boost nickel and cobalt recovery. We simplify complex workflows, reduce downtime, and create high-purity materials that drive sustainability and profitability. By solving key industry challenges, we’re making battery recycling more efficient, scalable, and cost-effective. GreenLIB is leading the way toward a circular economy where materials are recovered smarter and cleaner—because the future demands it.",
      action: "Recycle with us",
    },
    minerals: {
      title:
        "The essential metals and minerals that we obtain through our process.",
      action: "Select a mineral to learn more!",
      atomic: {
        title: "Atomic number {{atomicNumber}}",
        lithium: {
          name: "Lithium",
          num: "7",
          text: "Lithium is the lightest metal and the third-lightest element (after hydrogen and helium) on the periodic table. It has a low density and is highly reactive, which makes it useful for a variety of applications, including in batteries, ceramics, and even medication for certain health conditions.",
        },
        silver: {
          name: "Silver",
          num: "47",
          text: "Silver is sometimes used in the electrodes of batteries, but it is a relatively rare occurrence. However, silver is a highly recyclable metal that can be reused in the production of new batteries.",
        },
        manganese: {
          name: "Manganese",
          num: "25",
          text: "Manganese is a common component in lithium-ion batteries. It is often used in the cathode to enhance the battery's energy density. Manganese can be recycled from old batteries and used to produce new ones.",
        },
        iron: {
          name: "Iron",
          num: "26",
          text: "Iron is not typically used in batteries, but it is a common component of steel, which is used to make battery cases and other components. Recycled iron can be used to make new steel for these purposes.",
        },
        cobalt: {
          name: "Cobalt",
          num: "27",
          text: "Cobalt is used in lithium-ion batteries to increase their energy density. Cobalt is a valuable mineral, but its mining can have negative environmental impacts. Battery recycling can help reduce the need for new mining and conserve valuable resources.",
        },
        nickel: {
          name: "Nickel",
          num: "28",
          text: "Nickel is a key component in the cathode of many types of batteries, including nickel-cadmium, nickel-metal hydride, and lithium-ion batteries. Nickel can be recycled from old batteries and used to produce new ones.",
        },
        copper: {
          name: "Copper",
          num: "29",
          text: "Copper is used in many battery components, including wires, connectors, and terminals. Copper is also a highly recyclable metal that can be reused in the production of new batteries.",
        },
        aluminium: {
          name: "Aluminium",
          num: "13",
          text: "Aluminium is not directly used in batteries, but it is often used to make battery casings and other components. Recycling aluminium can help conserve energy and reduce greenhouse gas emissions.",
        },
        carbon: {
          name: "Carbon",
          num: "6",
          text: "Carbon is used in the anode of many types of batteries, including lithium-ion batteries. Carbon can be recycled from old batteries and used to produce new ones.",
        },
        silicon: {
          name: "Silicon",
          num: "14",
          text: "Silicon is a versatile metalloid abundant in the Earth's crust. With electrical conductivity, heat resistance, and oxygen affinity, it's crucial in electronics, solar panels, fiber optics, concrete, and glass production.",
        },
        electrolyte: {
          name: "Electrolyte",
          num: "LiPF6",
          text: "Lithium hexafluorophosphate (LiPF6) is an essential electrolyte in lithium-ion batteries, facilitating ion transfer between electrodes to enable energy storage and release. Its high ionic conductivity and stability make it a key ingredient for high-performance batteries in electronics and electric vehicles.",
        },
      },
    },
    process: {
      title: "Our process",
      flow: {
        step: {
          one: "Value-added stream",
          two: "Black mass (EoL/scrap batteries)",
          three: "GreenLIB",
          four: "Refined outputs (clean Ni/Co, lithium, graphite, aluminum)",
          five: "Recyclers (smelters / hydromet)",
          six: "Low-value stream",
          seven: "Black mass (EoL/scrap batteries)",
          eight: "Recyclers (smelters / hydromet)",
        },
      },
    },
    info: {
      one: {
        title: "Maximizing resource recovery",
        text: "We enhance the recovery of critical materials—lithium, graphite, nickel, cobalt, and electrolytes—by streamlining black mass processing. Our approach reduces waste, improves efficiency, and aligns with evolving regulations to drive a circular economy.",
      },
      two: {
        title: "Adaptable & scalable processing",
        text: "Our technology seamlessly integrates with smelters, recyclers, and black mass producers, handling diverse battery chemistries like NMC, LFP, and LMFP. This flexibility ensures cost-effective, efficient pre-treatment that enhances downstream recovery.",
      },
      three: {
        title: "Sustainable & cost-efficient operations",
        text: "We reduce reliance on hazardous waste permits by eliminating HF and PFAS formation while managing fluorine efficiently. Our process lowers acid use, cuts emissions, and simplifies waste disposal—making battery recycling greener and more profitable.",
      },
      four: {
        title: "Increasing asset value",
        text: "By complementing existing infrastructure, we help smelters and recyclers increase metal yields, reduce operating costs, and enhance profitability. Our solution boosts process efficiency, making battery recycling more viable at scale.",
      },
    },
    cta: {
      title: "Interested in learning more? Let's discuss how we can help you.",
      action: "Contact us",
    },
  },
  about: {
    hero: {
      title: "Our journey to a sustainable future",
      subtitle:
        "Launched in 2022, we are set to transform the material upcycling sector. With an innovative process for recovering valuable battery components, we’re turning industry challenges into sustainable solutions. Our technology not only boosts recycling rates but also cuts CO2 emissions and costs. Designed for decentralization, our onsite pre-treatment facilities fit right into existing supply chains, enhancing long-term sustainability. As demand for batteries climbs, we are poised to lead with scalable, eco-efficient upcycling of black mass for a more sustainable energy future.",
    },
    stat: {
      title: "Our mission",
      subtitle:
        "We are driven by a mission to transform the recycling industry – focusing on black mass upcycling. Our goal is to deliver unmatched value through a process that is as green as it is secure and efficient.",
      text: "Our commitment is to inspire every member of our team and our partners through our unwavering dedication to environmental preservation and relentless pursuit of innovation. We promise not just the feasibility of our methodology, but also the conscientious extraction of vital materials, all to bolster the worldwide shift towards clean energy.",
      one: {
        label:
          "We are set to revolutionize material recovery by 2030, aiming to process 16,500 metric tons of black mass annually from various sectors. Our advanced facilities specialize in extracting high-quality materials, including purified lithium, graphite, and electrolytes, as well as clean nickel/cobalt alloys.",
        value: "Aiming for 16,500 annual metric tons by 2030",
      },
      two: {
        label:
          "Our technology not only recovers vital materials but also exemplifies our commitment to waste management and pollution control. Our methodology is centered around rigorous fluorine management and PFAS reduction, which significantly lessens the environmental impact of battery recycling. We aim for an ecosystem where chemical and pollution waste is minimized, aligning with stringent environmental standards.",
        value: "Innovating for environmental excellence",
      },
      three: {
        label:
          "Our flexible processes proficiently handle various black mass types from NMC to LFP/LMFP or mix, reflecting our pledge to sustainability and quality in the face of diverse material chemistries. We facilitate the integration of cleaner and more efficient practices for smelters/pyro and hydrometallurgical processes, ensuring each step we take is a step towards a greener future.",
        value: "Adaptable across battery chemistries",
      },
    },
    values: {
      title: "Our values",
      subtitle:
        "At GreenLIB, we are committed to creating a sustainable future for our planet by providing a safe and efficient way to upcycle black mass.",
      innovation: {
        name: "Innovation",
        description:
          "At GreenLIB, we believe in the power of innovation to drive change. We are constantly exploring new and advanced methods to upcycle black mass in a more eco-friendly and sustainable way. Our team of experts works tirelessly to develop cutting-edge technologies that help us extract maximum value from these batteries while minimizing waste and environmental impact.",
      },
      integrity: {
        name: "Integrity",
        description:
          "Integrity is at the heart of everything we do. We hold ourselves to the highest ethical standards, and our commitment to transparency and honesty is unwavering. We strive to build trust and credibility with our customers, suppliers, and partners by always doing the right thing, even when it's difficult.",
      },
      environmentalResponsibility: {
        name: "Environmental responsibility",
        description:
          "We understand the urgency of the environmental challenges facing our planet today. As a responsible corporate citizen, we are committed to reducing our environmental impact and promoting sustainability. Our recycling process is designed to minimize waste and carbon emissions, conserve resources, and contribute to the circular economy.",
      },
      customerFocus: {
        name: "Customer focus",
        description:
          "Our customers are at the center of everything we do. We are committed to providing them with the highest quality of service, products, and solutions that meet their unique needs and expectations. We listen to their feedback and constantly strive to improve our processes and operations to better serve them.",
      },
      collaboration: {
        name: "Collaboration",
        description:
          "We believe that collaboration is essential to achieve our goals. We work closely with our partners, suppliers, and customers to create a more sustainable future for our planet. By working together, we can find innovative solutions to the environmental challenges we face and make a positive impact on the world.",
      },
    },
    cta: {
      title: "Learn about our unique and scalable approach.",
      action: "Learn more",
    },
  },
  contact: {
    title: "Contact",
    subtitle: "Let’s build the future of sustainable energy together.",
    form: {
      firstName: "First name",
      lastName: "Last name",
      company: "Company",
      email: "Email",
      message: "Message",
      errorMessage: "Required",
      action: "Let's talk!",
      success: "Thank you for reaching out! Someone will contact you soon.",
    },
    info: {
      text: "By 2030, we aim to process 16,500 metric tons of black mass annually, establishing ourselves as leaders in sustainable materials recovery. Our proprietary technology is at the forefront of upcycling black mass from various industries into valuable resources like lithium carbonate, graphite, electrolyte, and premium metal alloys. This process not only ensures high-purity inputs for battery production but also prioritizes environmental stewardship by minimizing waste and harmful substances. We collaborate directly with smelters and hydro processors to refine and resell our advanced solutions onsite. This approach reduces logistical and material costs, overcoming traditional recycling barriers. As demand for efficient, eco-friendly battery recycling escalates, we are poised to meet this need with our cost-effective, low CO2 emission technologies. Contact us to learn more about how we are powering the clean energy transition.",
    },
  },
  team: {
    title: "Meet our leadership",
    subtitle:
      "United by our dedication to sustainable energy and environmental protection, our team comprises top-tier innovators, visionaries, and specialists in the field.",
    advisory: "Advisory board",
    titles: {
      advisor: "Advisor",
      ceo: "CEO",
      coo: "COO",
      cto: "CTO",
      headRD: "Head of R&D",
    },
  },
  relationships: {
    title: "Our relationships",
  },
  batteryChart: {
    xLabel: "Lithium battery cost components",
    yLabel: "$ / kWh",
    legend: {
      cathode: "Cathode",
      anode: "Anode",
      electrolyte: "Electrolyte",
      separator: "Separator",
      otherMaterials: "Other materials",
      labor: "Labor",
      overhead: "Overhead",
      profitMargin: "Profit margin",
    },
  },
};

export default english;
